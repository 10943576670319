<template>
    <v-dialog :value="value" @input="$emit('input')" :content-class="'subscriptions_plan_modal'"
        max-width="546"
        overlay-color="#0C1324"
        overlay-opacity="0.9">

        <h2 class="modal_header">
            Cancel Subscription
        </h2>

        <div class="modal_content">
            You are about to cancel your subscription. You can still use premium features until
            <span v-if="value.subscription">
                {{ value.subscription.currentPeriodEnd | moment("MMM DD, YYYY")  }}
            </span>
            after which your subscription will not renew.
        </div>

        <div class="modal_wrap_actions">
            <button type="button" class="keep_it" v-ripple @click="dialog = false">Keep it</button>
            <button type="button" class="unsubscribe" v-ripple @click="cancel" :class="{loading: loading}">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                Cancel Subscription
            </button>
        </div>

    </v-dialog>
</template>

<script>
export default {
    name: "CancelAccountSubscription",
    props: ['value'],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        cancel() {
            this.loading = true;
            this.$emit('cancel', this.value);
        }
    }
}
</script>

<style lang="scss">

</style>
