<template>
	<div>
		<v-dialog
			v-if="paymentSuccess === false"
			:value="value"
			@input="$emit('input')"
			persistent
			:content-class="'create_new_account_modal'"
			max-width="550">

			<form id="payment-form">
				<div class="heading">
					<h2 class="heading_new_account">
						<!--					{{ selectedTypeAccount }} -->
						Account
					</h2>
				</div>
				<div class="wrapper_content h450">

					<div class="info_card_box">
						<div class="account_data" v-if="!createdLoader">
							<div class="description_info">
								<div class="image" :class="{default_ava: !paymentPerson.avatar}">
									<v-img
										v-if="paymentPerson.avatar"
										:src="paymentPerson.avatar"
										min-width="100%"
										min-height="100%"
									></v-img>
									<div v-if="!paymentPerson.avatar" class="default_ava"></div>
								</div>
								<div class="name_type">
									<div class="name">{{ paymentPerson.name }}</div>
									<div class="type">{{ paymentPerson['@type'] }}</div>
								</div>
							</div>
							<div class="price_info">
								<!--							<template v-if="selectedTypeAccount === 'Artist'">-->
								<template>${{commissionRates.artist_subscription_month}}/mo</template>
								<!--								<template v-if="selectedSubscription === 'monthly'">$0.99/mo</template>-->
								<!--								<template v-if="selectedSubscription === 'yearly'">$0.85/mo</template>-->
								<!--							</template>-->
								<!--							<template v-if="selectedTypeAccount === 'Label'">-->
								<!--								<template v-if="selectedSubscription === 'monthly'">$9.99/mo</template>-->
								<!--								<template v-if="selectedSubscription === 'yearly'">$8.50/mo</template>-->
								<!--							</template>-->
							</div>
						</div>
						<div class="card_data">
							<StripeElementPayment
								v-if="stripeOptions.clientSecret"
								ref="paymentRef"
								:pk="stripeToken"
								:elements-options="stripeOptions"
								:confirm-params="confirmParams"
								:redirect="'if_required'"
                                @error="submitPaymentCallback"
                                @success="submitPaymentCallback"
							></StripeElementPayment>
							<!--<circleLoader :load-complete="loading === false" v-if="loading === true"></circleLoader>-->
						</div>
					</div>
				</div>
				<div class="wrapper_button center">
					<button type="button" class="tetriatary_btn" v-ripple @click="closeModal">Close</button>
					<button type="button" class="tetriatary_btn pay" v-ripple @click="submitPayment" :class="{loading: submitStripeLoader}">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Next
					</button>
				</div>
				<div class="overlay custom_style_loader" v-if="createdLoader">
					<div class="progress_border">
						<v-progress-circular
							:size="250"
							:width="38"
							bg-color="rgba(255, 255, 255, 1)"
							color="#20C4F5"
							indeterminate
						></v-progress-circular>
					</div>
				</div>
			</form>

		</v-dialog>

		<!-- Success -->
		<v-dialog v-if="paymentSuccess === true" :value="value"
		          :content-class="'create_new_account_modal'"
		          max-width="550">
			<div>
				<div class="heading">
					<h2 class="heading_new_account">
						New Account
					</h2>
				</div>
				<div class="wrapper_content h450">
					<div class="info_card_box">
						<div class="card_data">
							<div class="success_status">
								<div class="status">Success!</div>
								<div class="image_status"></div>
								<div class="description_status">Manage subscriptions in <i>Account Settings -> Subscriptions</i></div>
							</div>
						</div>
					</div>
				</div>
				<div class="wrapper_button center">
					<div class="tetriatary_btn _button" v-ripple @click="doneAfterCreated">Done</div>
				</div>
			</div>
		</v-dialog>
	</div>

</template>

<script>
import {StripeElementPayment} from '@vue-stripe/vue-stripe';
import {mapActions, mapGetters} from "vuex";

import type Account from "@/models/accountM";

export default {
	name: "needLogin",
	props: ['value', 'clientSecret', 'paymentPerson'],
	components: {
		StripeElementPayment
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			stripeToken: process.env.VUE_APP_STRIPE_API_TOKEN,
			stripeOptions: {
				appearance: {
					variables: {
						fontSizeBase: '16px',
						colorText: '#ffffff',
						colorDanger: '#E94E4E',
						borderRadius: '5px',
						colorBackground: '#0C1324',
						colorIconTabSelected: '#ffffff',
					},
					rules: {
						'.Tab': {
							padding: '12px 12px 7px 12px',
							border: 'none',
							backgroundColor: '#182034',
							boxShadow: 'none'
						},
						'.Tab--selected, .Tab--selected:focus': {
							border: '1px solid #5F6986',
							boxShadow: 'none',
						},
						'.TabLabel': {
							color: '#ffffff',
						},
						'.Input': {
							boxShadow: 'none',
						},
						'.Input--invalid': {
							boxShadow: 'none',
							border: '1px solid #E94E4E'
						},
						'.Input:focus': {
							boxShadow: 'none',
							border: '1px solid #5F6986'
						},

						'.Input::placeholder': {
							color: '#7481A6',
						},
						'.Error': {
							fontSize: '12px',
							marginLeft: '5px'
						}
					}
				},
			},
			stripeElements: '',
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.host}/dashboard/accounts`,
			},
			submitStripeLoader: false,
			createdLoader: false,
			paymentSuccess: false,
		}
	},
	watch: {
		'subscriptionClientSecret'() {
			this.openPaymentForm();
		}
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		...mapGetters({
			// CENTRIFUGE dependencies
			authData: 'AUTH_DATA',
			centrifuge: 'CENTRIFUGE_CLIENT',
			subscriptionClientSecret: 'SUBSCRIPTION_CLIENT_SECRET',
			commissionRates: 'COMMISSION_RATES',
		})
	},
	created() {
		this.openPaymentForm();
	},
	methods: {
		...mapActions({
			// CENTRIFUGE client
			getCentrifuge: 'GET_CENTRIFUGE_CLIENT',
			getCommissionRates: 'GET_COMMISSION_RATES',
		}),
		openPaymentForm() {
			this.getCommissionRates().then(() => {
			}).catch(err => console.error(`getCommissionRates, ${err}`));

			this.stripeOptions.clientSecret = this.subscriptionClientSecret || this.clientSecret;

            // TODO: create channel(BackEnd) for artist subscription (new, renew)
			this.getCentrifuge()
				.then(() => {
                    let prefix = (this.paymentPerson instanceof Account && this.paymentPerson.isLabel()) ? 'record_label' : 'artist';
					const sub = this.centrifuge.newSubscription(`renew_${prefix}_subscription${this.authData.userId}`);
					const self = this;
						sub.on('publication', function (ctx) {
						console.log('ctx.data', ctx.data);
						if (ctx.data.status === 'success') {
							self.submitStripeLoader = false;
							self.paymentSuccess = true;
							// self.successDialog = true;
						}
					}).subscribe();

					this.centrifuge.connect();
				})
				.catch(err => console.error(`---- getCentrifuge, ${err}`));
		},
		async submitPayment() {
			this.submitStripeLoader = true;
            this.$refs.paymentRef.submit();
		},
		closeModal() {
			this.dialog = false;
			this.$emit('close');
		},
        submitPaymentCallback() {
            this.submitStripeLoader = false;
        },
		doneAfterCreated() {
            this.$emit('done', {
                'success': this.paymentSuccess,
                'person': this.paymentPerson
            });

			this.paymentSuccess = false;
			this.dialog = false;
		}
	},
}
</script>
