<template>
    <div class="p-settings full_page">
        <topWelcomeBanner></topWelcomeBanner>
        <nav class="layout_nav">
            <v-container>
                <ul ref="menu" @click="handleClick">
                    <li v-for="(item, index) in menuItems" :key="index" :class="{active: activeIndex === index}" :data-menu-index="index">
                        <router-link :to="{name: item.route}">
                            {{ item.title }}
                        </router-link>
                    </li>
                </ul>
                <!--                <ul>-->
                <!--                    <li>-->
                <!--                        <router-link :to="{name: 'security'}">Security</router-link>-->
                <!--                    </li>-->
                <!--                    <li>-->
                <!--                        <router-link :to="{name: 'privacy'}">Privacy</router-link>-->
                <!--                    </li>-->
                <!--                    <li>-->
                <!--                        <router-link :to="{name: 'notifications'}">Notifications</router-link>-->
                <!--                    </li>-->
                <!--                    <li>-->
                <!--                        <router-link :to="{name: 'payments'}">Payments</router-link>-->
                <!--                    </li>-->
                <!--                    <li>-->
                <!--                        <router-link :to="{name: 'subscriptions'}">Subscriptions</router-link>-->
                <!--                    </li>-->
                <!--                </ul>-->
            </v-container>

        </nav>
        <v-container>
            <slot></slot>
        </v-container>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";

export default {
    name: 'settingsPagesLayout',
    components: {
        topWelcomeBanner
    },
    data() {
        return {
            menuItems: [
                {id: 'security', title: 'Security', route: 'security'},
                {id: 'privacy', title: 'Privacy', route: 'privacy'},
                {id: 'notifications', title: 'Notifications', route: 'notifications'},
                {id: 'payments', title: 'Payments', route: 'payments'},
                {id: 'subscriptions', title: 'Subscriptions', route: 'subscriptions'},
            ],
            activeIndex: null
        };
    },
    mounted() {
        this.scrollToActive();
    },
    methods: {
        handleClick(event) {
            const target = event.target;
            if (target.tagName === 'A') {
                this.activeIndex = this.activeIndex = parseInt(target.parentElement.getAttribute('data-menu-index'));
                this.$nextTick(() => {
                    this.scrollToActive();
                });
            }
        },
        scrollToActive() {
            this.$nextTick(() => {
                const menu = this.$refs.menu;
                if (menu) {
                    const activeItem = menu.querySelector('.active');

                    if (activeItem) {
                        activeItem.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        });

                        const menuRect = menu.getBoundingClientRect();
                        const activeRect = activeItem.getBoundingClientRect();
                        const activeItemCenter = (activeRect.left + activeRect.right) / 2;
                        const menuCenter = menuRect.width / 2;
                        const scrollLeft = activeItemCenter - menuCenter;
                        menu.scrollLeft += scrollLeft;
                    }
                }

            });
        }
    }
}
</script>

