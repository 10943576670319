import store from '../store';
import type AccountM from "@/models/accountM";
export default class SubscriptionManager {
    create(account: AccountM): Promise<void> {
        if (account.isArtist()) {
            return  store.dispatch('CREATE_ARTIST_SUBSCRIPTION', account.id)
                .catch(err => console.error(`CREATE_ARTIST_SUBSCRIPTION, ${err}`))
        }

        if (account.isLabel()) {
            return  store.dispatch('CREATE_RECORD_LABEL_SUBSCRIPTION', account.id)
                .catch(err => console.error(`CREATE_RECORD_LABEL_SUBSCRIPTION, ${err}`))
        }

        throw new Error('Unknown account type for "create" action')
    }

    renew(account: AccountM): Promise<void> {
        if (account.isArtist()) {
            return  store.dispatch('RENEW_ARTIST_SUBSCRIPTION', {
                artistID: account.id,
                subscriptionID: account.subscription.id,
            })
            .then(() => store.getters['SUBSCRIPTION_CLIENT_SECRET'])
            .catch(err => console.error(`RENEW_ARTIST_SUBSCRIPTION, ${err}`))
        }

        if (account.isLabel()) {
            return  store.dispatch('RENEW_RECORD_LABEL_SUBSCRIPTION', {
                labelID: account.id,
                subscriptionID: account.subscription.id,
            })
            .then(() => {
                return store.getters['RECORD_LABEL_CLIENT_SECRET']
            })
            .catch(err => console.error(`RENEW_RECORD_LABEL_SUBSCRIPTION, ${err}`))
        }

        throw new Error('Unknown account type for "renew" action')
    }

    cancel(account: AccountM): Promise<void> {
        if (account.isArtist()) {
            return  store.dispatch('REMOVE_ARTIST_SUBSCRIPTION', {
                artistID: account.id,
                subscriptionID: account.subscription.id,
            }).catch(err => console.error(`REMOVE_ARTIST_SUBSCRIPTION, ${err}`))
        }

        if (account.isLabel()) {
            return  store.dispatch('REMOVE_RECORD_LABEL_SUBSCRIPTION', {
                labelID: account.id,
                subscriptionID: account.subscription.id,
            }).catch(err => console.error(`REMOVE_RECORD_LABEL_SUBSCRIPTION, ${err}`))
        }

        throw new Error('Unknown account type for "cancel" action')
    }

    unCancel(account: AccountM): Promise<void> {
        if (account.isArtist()) {
            return  store.dispatch('UNCANCEL_ARTIST_SUBSCRIPTION', {
                artistID: account.id,
                subscriptionID: account.subscription.id,
            }).catch(err => console.error(`UNCANCEL_ARTIST_SUBSCRIPTION, ${err}`))
        }

        if (account.isLabel()) {
            return  store.dispatch('UNCANCEL_RECORD_LABEL_SUBSCRIPTION', {
                labelID: account.id,
                subscriptionID: account.subscription.id,
            }).catch(err => console.error(`UNCANCEL_RECORD_LABEL_SUBSCRIPTION, ${err}`))
        }

        throw new Error('Unknown account type for "un cancel" action')
    }
}
