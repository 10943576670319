<template>
    <div class="membership_plan account_subscript" :class="{ 'red_plan': isRedPlanColor(), 'orange_plan': isOrangePlanColor()}">
        <div class="main_info">
            <div class="name_plan">{{ account.type }}</div>
            <span class="description_plan">
                <span class="account" @click="redirectToAccount(account)">{{ account.name }}</span>
            </span>

            <div class="price_plan">
	            <template v-if="account.type === 'Artist'">${{rates.artist_subscription_month}}/month</template>
	            <template v-if="account.type === 'Label'">${{rates.record_label_subscription_month}}/month</template>
            </div>
            <div class="billed">Billed Monthly</div>

            <template v-if="account.hasActiveSubscription()">
                <div class="description_price">Renews {{ account.subscription.currentPeriodEnd | moment('LL') }}</div>
            </template>

            <template v-if="account.hasCanceledSubscription()">
                <div class="description_price">
                    <div class="subscription_status">
                        Canceled
                    </div>
                    <div class="end_date">
                        <template v-if="new Date() > new Date(account.subscription.currentPeriodEnd)">Expired</template>
                        <template v-else>Expires</template>
                        {{ account.subscription.currentPeriodEnd | moment('ll') }}
                    </div>
                </div>
            </template>

            <template v-if="account.subscription && account.subscription.isExpired()">
                <div class="description_price">
                    <div class="subscription_status">
                        Issue with billing
                    </div>
                    <div class="end_date" >
                        Expired {{ account.subscription.expiredDate() | moment('ll') }}
                    </div>
                </div>
            </template>

            <template v-if="account.subscription && account.subscription.isExpires()">
                <div class="description_price">
                    Issue with billing
                    <div class="end_date" v-if="account.subscription.currentPeriodEnd && account.subscription.issue.type === 'billing_update'">
                        Fix before {{ account.subscription.issue.date | moment('ll') }}
                    </div>
                </div>
            </template>

            <div class="description_price expires" v-if="!account.subscription">Issue with billing</div>
        </div>

        <!-- *** Buttons subscription *** -->

        <!-- Active subscription -->
        <template v-if="account.hasActiveSubscription()">
            <div v-if="manageFlag" class="manage_plan active">
                <button type="button" class="secondary_btn" v-ripple @click="cancel(account)">Cancel Subscription</button>
                <button type="button" disabled class="secondary_btn" v-ripple>Switch to Yearly</button>
                <button type="button" class="tetriatary_btn" v-ripple @click="manageFlag = false">Dismiss</button>
            </div>

            <button v-if="!manageFlag" type="button" class="secondary_btn manage_plans" @click="manageFlag = true" v-ripple>
                <template>Manage</template>
            </button>
        </template>

        <!-- Issue with billing -->
        <template v-if="!account.hasActiveSubscription() && account.subscription && account.subscription.issue">
            <button v-if="account.subscription.issue.type === 'cancel_expires'" @click="unCancel(account)" type="button" class="secondary_btn manage_plans" :class="{loading: account.isBusy()}" v-ripple>
                <template>Un-Cancel</template>
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
            </button>

            <button v-if="account.subscription.issue.type === 'cancel_expired' || account.subscription.issue.type === 'billing_expired' || ['incomplete', 'incomplete_expired'].includes(account.subscription.status)"
                    type="button"
                    class="secondary_btn manage_plans"
                    @click="renew(account)" v-ripple
                    :class="{loading: account.isBusy()}">
                <template>Renew</template>
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
            </button>

            <button v-if="account.subscription.issue.type === 'billing_update'"
                    type="button" class="secondary_btn manage_plans" @click="renew(account)" v-ripple
                    :class="{loading: account.isBusy()}">
                <template>Fix Billing</template>
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
            </button>
        </template>

        <!-- None subscription -->
        <button v-if="!account.subscription" type="button" class="secondary_btn manage_plans" @click="create(account)"
                :class="{loading: account.isBusy()}" v-ripple>
            <template>Renew</template>
            <v-progress-circular :width="2" indeterminate></v-progress-circular>
        </button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


// models
import AccountM from "@/models/accountM";
import SubscriptionManager from "@/services/subscriptionManager";

export default {
    name: "AccountSubscription",
    props: {
        account: AccountM,
    },
    data() {
        return {
            manageFlag: false,
            subscription: new SubscriptionManager(),
        }
    },
    computed: {
        ...mapGetters({
            rates: 'COMMISSION_RATES',
        }),
    },
    methods: {
        ...mapActions({
            getArtistReleases: 'GET_ARTIST_ALBUMS'
        }),

        isRedPlanColor(): boolean {
            let rule1 = this.account.subscription?.currentPeriodIsEnded() && !this.account.hasActiveSubscription();
            let rule2 = this.account.subscription?.isExpired();

            return !this.account.subscription || rule1 || rule2;
        },

        isOrangePlanColor(): boolean {
            let rule1 = !this.account.subscription?.currentPeriodIsEnded();
            let rule2 = !this.account.hasActiveSubscription() && this.account.subscription?.canActivate;

            return this.account.subscription && rule1 && rule2
        },

        create(account: AccountM) {
            account.changeBusy(true);
            this.$emit('create', account);
        },

        renew(account: AccountM) {
            account.changeBusy(true);
            this.$emit('renew', account);
        },

        cancel(account: AccountM) {
            this.manageFlag = false;
            this.$emit('cancel', account)
        },

        unCancel(account: AccountM) {
            account.changeBusy(true);
            this.$emit('un-cancel', account)
        }
    }
}
</script>

<style scoped>
</style>
